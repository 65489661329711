<template>
	<div id="test_manage">
		<el-row class="top">
			<el-col :span="4">
				<el-button size="medium" icon="el-icon-plus" type="primary" @click="pageConf.insert = true"
					v-if="$pubFun_.verify_permiss($api_.state.Task.TestManage.add.token,$menu_.state.roleNode)">新建地址
				</el-button>
			</el-col>
			<el-col :span="6" :offset="14">
				<el-input placeholder="请输入测试地址名称、链接" clearable v-model="tableInfo.conf.keywords"
					@keyup.enter="searchTestPlace" @clear="searchTestPlace">
					<template #append>
						<i class="iconfont icon-edit" @click="searchTestPlace">&#xe6ab;</i>
					</template>
				</el-input>
			</el-col>
		</el-row>
		<div class="table">
			<CusTable ref="cusTable" title="测试管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" @editIcon="openEditPage" @chosePage="tableChosePage"
				@showQrcode="showQrcode" @setPageSize="tablePageSize" :ispaging="true"></CusTable>
		</div>
		<div class="dialog-page">
			<!-- 新建测试地址 start -->
			<el-dialog title="新建测试地址" v-model="pageConf.insert" destroy-on-close :width="500">
				<el-form :model="insertForm.datas" :rules="insertForm.rules" ref="formRef">
					<el-form-item prop="title">
						<el-input v-model="insertForm.datas.title" class="form-input" placeholder="请输入测试地址名称">
						</el-input>
					</el-form-item>
					<el-form-item prop="link">
						<el-input v-model="insertForm.datas.link" class="form-input" placeholder="请输入测试地址链接">
						</el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="insertSubmitForm()">确认新建
						</el-button>
						<el-button @click="pageConf.insert = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 新建测试地址 end -->
			<!-- 编辑测试地址 start -->
			<el-dialog title="编辑测试地址" v-model="pageConf.edit" destroy-on-close :width="500">
				<el-form :model="editForm.datas" :rules="editForm.rules" ref="formRef">
					<el-form-item prop="title">
						<el-input v-model="editForm.datas.title" class="form-input" placeholder="请输入测试地址名称">
						</el-input>
					</el-form-item>
					<el-form-item prop="link">
						<el-input v-model="editForm.datas.link" class="form-input" placeholder="请输入测试地址链接">
						</el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="editSubmitForm()">确认编辑
						</el-button>
						<el-button @click="pageConf.edit = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 编辑测试地址 end -->
			<!-- 访问二维码 start -->
			<el-dialog custom-class="qrcode_img" :title="pageConf.qrcode.title" v-model="pageConf.qrcode.status"
				destroy-on-close :show-close="false" :width="300" center>
				<el-image :src="pageConf.qrcode.url" fit="fill"></el-image>
			</el-dialog>
			<!-- 访问二维码 end -->
		</div>
	</div>
</template>

<script type="text/javascript">
	import tableColumns from '@/assets/json/task/table/test-place-cloumns.json'
	import CusTable from '@/components/common/CusTable.vue'
	import qrCode from 'qrcode'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'test_manage',
		components: {
			CusTable
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				insert: false,
				edit: false,
				qrcode: {
					status: false,
					title: '',
					url: ''
				},
				search_user_list: [],
				search_user_chose: [],
				select_check_uid: '',
				select_isload: false,
				submit_btn_isload: false
			})

			// 顶部搜索栏
			const searchTestPlace = (type) => {
				// 重置分页参数
				tableInfo.conf.curPage = 1

				// 执行搜索请求
				loadTestPlaceList()
			}

			// 表单-挂载
			const formRef = ref()

			/*测试地址列表*/
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'TestManage'),
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 15,
					dataTotal: 0,
					sigleHide: true,
					keywords: '',
					emptyText: ''
				}
			})

			// 获取测试地址列表
			const loadTestPlaceList = () => {
				// 重置参数
				tableInfo.list = []
				tableInfo.conf.loadStatus = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.TestManage.list.url, {
						page: tableInfo.conf.curPage,
						limit: tableInfo.conf.pageLimit,
						team_id: tableInfo.conf.team_id,
						duty_id: tableInfo.conf.duty_id,
						keywords: tableInfo.conf.keywords
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放测试地址数据
							tableInfo.list = respon.Data.datas
							tableInfo.conf.dataTotal = respon.Data.Count

							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = '没有更多的数据了~'
						} else {
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = respon.Message
						}
					}).catch(error => {
						tableInfo.conf.loadStatus = false
						tableInfo.conf.emptyText = "服务器连接失败，请稍后重试"
					})
			}

			// 设置table当前页码变化
			const tableChosePage = (curPage) => {
				// 存放当前页面
				tableInfo.conf.curPage = curPage

				// 重新加载table数据
				loadTestPlaceList()
			}

			// 设置table每页页码数
			const tablePageSize = (limit, page) => {
				// 存放页码数
				tableInfo.conf.pageLimit = limit

				// 判断查询当前页  *  条数 > 总条数不进行查询
				if (page === 1 || limit * page <= tableInfo.conf.dataTotal) {
					// 重新加载table数据
					loadTestPlaceList()
				}
			}

			// 查询访问二维码
			const showQrcode = (index) => {
				// 设置参数配置信息
				let opts = {
					errorCorrectionLevel: 'H',
					type: 'image/jpeg',
					quality: 0.3,
					margin: 1,
					color: {
						dark: "#ffffff",
						light: "#409eff"
					}
				}

				// 生成二维码
				qrCode.toDataURL(tableInfo.list[index].link, opts, function(err, url) {
					if (err) throw err

					pageConf.qrcode.title = tableInfo.list[index].title
					pageConf.qrcode.url = url
				})

				// 打开弹出层
				pageConf.qrcode.status = true
			}
			/*测试地址列表*/

			/*新建测试地址*/
			// 新建测试地址-数据表单内容
			const insertForm = reactive({
				datas: {
					title: '',
					link: ''
				},
				rules: {
					title: {
						required: true,
						message: '请输入测试地址名称',
						trigger: 'blur'
					},
					link: {
						required: true,
						message: '请输入测试地址链接',
						trigger: 'blur'
					}
				}
			})

			// 新建测试地址-提交测试地址数据
			const insertSubmitForm = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.TestManage.add.url, insertForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重置表单数据
									insertForm.datas.title = ''
									insertForm.datas.link = ''

									// 重新获取测试地址列表
									loadTestPlaceList()

									// 重置配置信息
									pageConf.insert = false
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*新建测试地址*/

			/*编辑测试地址*/
			// 编辑测试地址-数据表单内容
			const editForm = reactive({
				datas: {
					id: '',
					title: '',
					link: ''
				},
				rules: {
					title: {
						required: true,
						message: '请输入测试地址名称',
						trigger: 'blur'
					},
					link: {
						required: true,
						message: '请输入测试地址链接',
						trigger: 'blur'
					}
				}
			})

			// 编辑测试地址-打开编辑页面
			const openEditPage = (index) => {
				// 设置表单信息
				editForm.datas.id = tableInfo.list[index].id
				editForm.datas.title = tableInfo.list[index].title
				editForm.datas.link = tableInfo.list[index].link

				// 重置配置信息
				pageConf.edit = true
			}

			// 编辑测试地址-提交测试地址数据
			const editSubmitForm = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.TestManage.edit.url, editForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新获取测试地址列表
									loadTestPlaceList()

									// 重置配置信息
									pageConf.edit = false
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*编辑测试地址*/

			/*初次自动加载*/
			loadTestPlaceList()
			/*初次自动加载*/

			return {
				pageConf,
				searchTestPlace,
				formRef,
				tableInfo,
				tableChosePage,
				tablePageSize,
				showQrcode,
				insertForm,
				insertSubmitForm,
				editForm,
				openEditPage,
				editSubmitForm
			}
		}
	})
</script>

<style lang="less">
	#test_manage {
		.qrcode_img .el-dialog__body {
			text-align: center !important;
		}
	}
</style>
